@font-face {
    font-family: 'Migra';
    src: local('.'), local('.'),
        url('Migra-Extrabold.woff2') format('woff2'),
        url('Migra-Extrabold.woff') format('woff'),
        url('Migra-Extrabold.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Migra';
    src: local('.'), local('.'),
        url('Migra-Extralight.woff2') format('woff2'),
        url('Migra-Extralight.woff') format('woff'),
        url('Migra-Extralight.ttf') format('truetype');
    font-weight: 100;
    font-style: light;
}

@font-face {
    font-family: 'Migra';
    src: local('.'), local('.'),
        url('./MigraItalic-ExtralightItalic.woff2') format('woff2'),
        url('./MigraItalic-ExtralightItalic.woff') format('woff'),
        url('./MigraItalic-ExtralightItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Migra';
    src: local('.'), local('.'),
        url('./MigraItalic-ExtraboldItalic.woff2') format('woff2'),
        url('./MigraItalic-ExtraboldItalic.woff') format('woff'),
        url('./MigraItalic-ExtraboldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Migra';
    src: local('.'), local('.'),
        url('Migra-Extrabold.woff2') format('woff2'),
        url('Migra-Extrabold.woff') format('woff'),
        url('Migra-Extrabold.ttf') format('truetype');
    font-weight: 700;
    font-style: light;
}

body {
    font-family: 'Migra';
}